<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-09 09:40:40
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-01 17:04:42
-->
<template>
    <div class="home-wrap">
        <div class="hw-title flex-sbc">
            <img class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240509/e67d3671835aaedf99c9631d006ac7ca.png" alt="">
            <div class="icon-wrap" @click="clickIsShow">
                <i v-if="isDrawer" class="icon el-icon-close"></i>
                <i v-else class="icon el-icon-s-operation"></i>
            </div>
        </div>
        <div class="hw-content">
            <div class="hw-c-box">
                <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/cc4ed6d2f9001f00f196d11c09c5578b.png" alt="有库云进销存">
                <img class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/f7743f92137cadf44bdab2556552ea2a.png" alt="有库云进销存">
            </div>
            <div class="hw-c-title">
                <div class="title">心中有数，决策有据，有条有理有库云。</div>
                <div class="tag">个体小微企业在经营规范、提高效率、控制成本方面的智能助理。</div>
                <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/edf5dbbd2197703bc4187e25a8006849.png" alt="有库云进销存">
                <div class="btn flex-cc" @click="isDownload = true">登录小程序端</div>
            </div>
        </div>
        <div class="hw-do-wrap">
            <div class="dw-title">有库云能为您做什么？</div>
            <div class="dw-tag">销售规范、库存精准、账目清晰、数据全面这些生意中至关重要的管理因素的优化，是我们始终坚持的服务。</div>
            <div class="dw-image">
                <swiper class="i-swiper" :options="swiperOption" ref="mySwiper">
                    <swiper-slide class="w-slide" v-for="(item,index) in swiperList" :key="index">
                        <div class="i-item">
                            <img class="img" :src="item.img" alt="有库云进销存">
                            <div class="item">
                                <div class="tit">{{item.title}}</div>
                                <div class="tip">{{item.tag}}</div>
                            </div>
                        </div>
                    </swiper-slide>
                    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                </swiper>
            </div>
        </div>
        <div class="hw-box-wrap">
            <div class="b-title">规范销售流程、提升销售效率</div>
            <swiper class="b-swiper" :options="swiperOption1">
                <swiper-slide class="w-slide" v-for="(item,index) in swiperList1" :key="index">
                    <div class="i-item">
                        <img class="img" :src="item.img" alt="有库云进销存">
                        <div class="tip">{{item.tag}}</div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="hw-box-wrap" style="background: #fff">
            <div class="b-title">精细库存管理、优化库存成本</div>
            <swiper class="b-swiper" :options="swiperOption1">
                <swiper-slide class="w-slide" v-for="(item,index) in swiperList2" :key="index">
                    <div class="i-item">
                        <img class="img" :src="item.img" alt="有库云进销存">
                        <div class="tip">{{item.tag}}</div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="hw-box-wrap">
            <div class="b-title">全面清晰记账、避免账目混乱</div>
            <swiper class="b-swiper" :options="swiperOption1">
                <swiper-slide class="w-slide" v-for="(item,index) in swiperList3" :key="index">
                    <div class="i-item">
                        <img class="img" :src="item.img" alt="有库云进销存">
                        <div class="tip">{{item.tag}}</div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="hw-box-wrap" style="background: #fff">
            <div class="b-title">实时生成报表、及时发现问题</div>
            <swiper class="b-swiper" :options="swiperOption1">
                <swiper-slide class="w-slide" v-for="(item,index) in swiperList4" :key="index">
                    <div class="i-item">
                        <img class="img" :src="item.img" alt="有库云进销存">
                        <div class="tip">{{item.tag}}</div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="wp-do-wrap">
            <div class="dw-title">智能革新，让卓越管理触手可及</div>
            <div class="dw-box">
                <div class="db-item">
                    <div class="title">多端互联，云端数据同步，高效协同管理。</div>
                    <div class="content">有库云作为云进销存软件，可实现数据在多端实时同步，可利用微信小程序登录有库云移动端，也可通过电脑端应用支持Windows系统、MacOS系统以及网页端进行登录操作，并且支持大量的数据存储及备份。让经营者及员工不受设备限制，高效地进行业务管理。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/f0fe3a20b585f3592785c2788ed3266b.png" alt="">
                </div>
                <div class="db-item" style="background: #fff;">
                    <div class="title">订单自动化，实现高效简洁的流程。</div>
                    <div class="content">实现订单处理自动化，包括订单处理出库和入库及记账，减少手动操作，提高订单处理速度。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/04cd0c22479b1049ef6a4266f63827f9.png" alt="">
                </div>
                <div class="db-item" style="background: #fff;">
                    <div class="title">业务智能和数据分析，让数据驱动决策。</div>
                    <div class="content">提供强大的数据分析工具，帮助用户从销售和库存数据中获得洞察，支持数据驱动的决策。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/3cc3c0ef48e26c4a5cd2ff684aaf73ea.png" alt="">
                </div>
            </div>
        </div>
        <div class="hw-f-tip">东有创新©粤TCP备2023127805号-1</div>
        <div style="height: 80px"></div>
        <div class="hw-foot">
            <div class="hw-f-news flex-fec">
                <el-popover
                    v-model="isDrawerNews"
                    width="200"
                    placement="top-start"
                    trigger="hover">
                    <template #default>
                        <div class="p-phone-wrap flex-sbc">
                            <img class="ppw-img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240513/68d3193d8f603342d05c763982fa5d66.png" alt="">
                            <a class="ppw-info" href="tel:400-895-7887">
                                <div class="name">咨询电话</div>
                                <div class="phone">400-895-7887</div>
                            </a>
                        </div>
                    </template>
                    <template #reference>
                        <img @click="isDrawerNews = true" class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240513/f6a923568022cacd592a0776f49fa804.gif" alt="">
                    </template>
                </el-popover>
            </div>
            <div class="hw-f-btn flex-sbc">
                <div @click="clickOpen" class="btn open flex-cc">免费开通</div>
                <div @click="clickDemo" class="btn flex-cc">免费预约演示</div>
            </div>
        </div>
        <!-- 菜单 -->
        <el-drawer
            size="70%"
            :with-header="false"
            :visible.sync="isDrawer">
                <div class="drawer-wrap">
                    <div class="dw-item flex-sbc" @click="clickProduct">
                        <div class="title">服务</div>
                        <div class="icon">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="dw-item flex-sbc" @click="clickPrice">
                        <div class="title">价格</div>
                        <div class="icon">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="dw-item flex-sbc" @click="clickAboutUs">
                        <div class="title">关于我们</div>
                        <div class="icon">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
        </el-drawer>
        <el-dialog
            :visible.sync="isDownload"
            width="320px"
            :show-close="false">
            <div class="download-wrap flex-coc">
                <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240516/dfe8eabab2bad803e072c0db27bfdaeb.jpg" alt="">
                <div class="tip">长按/扫码二维码跳转有库云小程序</div>
            </div>
        </el-dialog>
        <!-- 消息 -->
        <!-- <el-drawer
            size="85%"
            :with-header="false"
            direction='btt'
            custom-class="drawer-news-wrap"
            :visible.sync="isDrawerNews">
                <div class="news-wrap">
                    <div class="nw-title flex-sbc">
                        <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240509/4a38157246c66c15f0ad3870206ff7ec.png" alt="">
                        <div class="icon" @click="isDrawerNews = false">
                            <i class="el-icon-minus"></i>
                        </div>
                    </div>
                    <div class="nw-tip">请您在沟通中遵循当地法律法规，有库云工作人员将竭答您的问题</div>
                    <div class="nw-box">
                        <div class="nw-question">有库云智能客服为您服务！请问有什么可以帮到您？</div>
                        <div class="nw-question">
                            <div class="nq-title">常见问题   Q&A</div>
                            <div class="nq-item">软件是否是不同电脑数据实时同步的？</div>
                            <div class="nq-item">安装是否会占用手机或电脑设备大量内存？</div>
                            <div class="nq-item">版本人员数不够用，能否进行账号空间升级？</div>
                            <div class="nq-item">软件功能担心团队成员吃不透，用不起来怎么办？</div>
                            <div class="nq-item">是否支持多个门店的开单管理、不同种类的商品管理？</div>
                            <div class="nq-item">打印销售单、出库单以及购销合同时内容能否自定义？</div>
                            <div class="nq-item">开单时是否有不同客户历史价格记录？</div>
                            <div class="nq-item">我们有些员工负责多个岗位的事情，是否需要多个账号？</div>
                        </div>
                    </div>
                    <div class="nw-input">
                        <el-input placeholder="输入信息" v-model="keyword">
                            <template slot="append">
                                <div class="btn">
                                    <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/9f5d57e05cca860039ae8feac8a3b62e.png" alt="">
                                </div>
                            </template>
                        </el-input>
                    </div>
                </div>
        </el-drawer> -->
    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
    components: {
        swiper,
        swiperSlide
    },
    data () {
        return {
            isDrawer: false,
            isDrawerNews: false,
            keyword: '',
            isDownload: false,

            swiperList: [{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/268c8737e212a64765c9a2aa5137edc7.png',
                title: '规范销售流程,提升销售效率',
                tag: '通过自动化销售流程，减少人工干预避免错误和延误，实现快速响应客户求。'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/4a3730641082eae19f1e7e9b1415c35c.png',
                title: '精细库存管理,优化库存成本',
                tag: '利用先进的库存管理算法，实时监控库存水平，预测需求，减少过剩或缺货风险，有效降低库存成本。'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/3b0fb9890595273057982d650c637a4b.png',
                title: '全面清晰记账,避免账目混乱',
                tag: '提供详尽的记账功能，确保每一笔交易都有迹可循，账目清晰，便于审计和财务分析，避免财务混乱。'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/401f7654e4c7c6ddb587a5cb99c44354.png',
                title: '实时生成报表,及时发现问题',
                tag: '系统能够实时生成各种财务和业务报表，帮助管理者快速把握经营状况，及时发现并解决潜在问题。'
            }],

            swiperList1: [{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/7968d14b0532c3746203ef7722b65aea.png',
                tag: '快速识别历史客户价格，提高开单效率'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/65a8316e2d626c0e4a1d101035306aa2.png',
                tag: '自动关联订单应收，方便查询回款'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/a8df06bbff222faadf50d4545fabd65b.png',
                tag: '订单设置审核，无过审不出库'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/1996a8c7ee6a43d2fb7463411dedb14b.png',
                tag: '出库单自动引用订单，协同仓库高效出货'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/cd86646e59ed2e6078e1c2e3c815f0aa.png',
                tag: '支持单据、合同快速生成打印'
            }],
            swiperList2: [{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/c3405fe7d7396f9cac26b86e29c6599f.png',
                tag: '商品库存根据业务实时统计，了解库存情况'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/e90afe22dcb532121f41697eca6abb09.png',
                tag: '商品实现批次管理，更精准的把控库存出入'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/44d7744a630e5de6efaa7f62b64aad22.png',
                tag: '支持多仓调拨，满足仓库业务需求'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/3c1b9fe6d069e2b3732e84e153492ec0.png',
                tag: '设置商品预警，避免销售缺货'
            }],
            swiperList3: [{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/fe9b34529891dc769b1279a6c77ea488.png',
                tag: '自动汇总待结算订单，不漏记错记'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/e6b1e1bbf09fe0f80f257616355a1ead.png',
                tag: '自定义添加记账类型，出入账目全面记录'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/f80008a9c1356c8ea1cf0903623576f4.png',
                tag: '业务进项票、销项票发票管理，避免报税漏报'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/561d3fc8ffe4f50caa5352007a7b4990.png',
                tag: '支持对账单批量合并结算记账，高效快捷记账'
            }],
            swiperList4: [{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/176f7de631ae1559172117281288c07c.png',
                tag: '实时汇总数据进行统计分析，了解经营情况'
            },{
                img: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/8975c5394baf7793a76a8c890d2a443c.png',
                tag: '快速生成报表，及时了解经营细节'
            }],
            swiperOption1: {
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                }
            },

            swiperOption: {
                loop: true,
                // autoplay: {
                //     delay: 3000,
                //     stopOnLastSlide: false,
                //     disableOnInteraction: false
                // },
                spaceBetween: 20,
                slidesPerView: 'auto',
                centeredSlides: true,
            }
        }
    },
    mounted () {

    },
    methods: {
        // 价格
        clickPrice(){
            this.$router.push("./webPrice")
        },
        // 关于我们
        clickAboutUs(){
            this.$router.push("./webAboutUs")
        },
        // 产品
        clickProduct(){
            this.$router.push("./webProduct")
        },
        // 预约演示
        clickDemo(){
            this.$router.push("./webDemonstration")
        },
        // 立即开通
        clickOpen(){
            this.$router.push("./webOpnePro")
        },
        clickIsShow(){
            this.isDrawer = !this.isDrawer
        }
    },
}
</script>

<style lang='scss'>
.home-wrap{
    .hw-title{
        background: #fff;
        padding: 0px 20px;
        height: 50px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        box-shadow: 0px 0px 10px 4px rgba(100, 100, 100, 0.05);
        .image{
            width: 65px;
            height: 15px;
            vertical-align: bottom;
        }
        .icon-wrap{
            .icon{
                font-size: 26px;
                color: #101010;
                font-weight: bold;
            }
        }
    }
    .hw-content{
        // height: calc(100vh - 50px);
        // overflow-y: auto;
        .hw-c-box{
            background-image: url('../../assets/web_backgrounds.png');
            background-size: 100% 100%;
            height: 400px;
            position: relative;
            .logo{
                margin: 30px 0 0 30px;
                width: 190px;
                height: 32px;
            }
            .image{
                width: 360px;
                height: auto;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%,0);
            }
        }
        .hw-c-title{
            padding: 0 20px;
            .title{
                font-size: 20px;
                color: #000;
                font-weight: bold;
            }
            .tag{
                font-size: 12px;
                color: #979797;
                padding: 10px 0;
            }
            .img{
                width: 210px;
                height: 32px;
                margin: 20px 0;
            }
            .btn{
                font-size: 14px;
                color: #000;
                width: 125px;
                height: 45px;
                border-radius: 6px;
                border: 1px solid #101010;
            }
        }
    }
    .hw-do-wrap{
        padding: 20px;
        margin-top: 50px;
        .dw-title{
            font-size: 20px;
            color: #000;
            font-weight: bold;
        }
        .dw-tag{
            font-size: 14px;
            color: #000;
            line-height: 26px;
            padding: 20px 0;
        }
        .dw-image{
            padding: 20px 0;
            .swiper-container {
                padding-right: 50px;
            }
            .i-swiper{
                height: 100%;
                border-radius: 10px;
                .w-slide{
                    height: 100%;
                    .i-item{
                        width: 100%;
                        .img{
                            height: 170px;
                            width: 100%;
                        }
                        .item{
                            position: relative;
                            border-radius: 10px;
                            margin-top: -20px;
                            padding: 20px;
                            height: 130px;
                            background: #fff;
                            margin-bottom: 10px;
                            box-shadow: 0px 0px 10px 4px rgba(100, 100, 100, 0.1);
                            .tit{
                                font-size: 20px;
                                color: #000;
                                font-weight: bold;
                            }
                            .tip{
                                padding-top: 20px;
                                font-size: 14px;
                                color: #000;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
    .hw-box-wrap{
        padding: 30px 20px;
        background: #f3f8ff;
        .b-title{
            font-size: 20px;
            color: #000;
            font-weight: bold;
            padding: 5px 0px;
        }
        .b-swiper{
            padding: 10px 0;
            .w-slide{
                width: 100%;
                .i-item{
                    width: 100%;
                    .img{
                        width: 100%;
                        padding-bottom: 10px;
                    }
                    .tip{
                        font-size: 16px;
                        color: #000;
                        text-align: center;
                        padding-bottom: 20px;
                    }
                }
            }
        }
        .swiper-pagination{
            bottom: 0px;
            .swiper-pagination-bullet{
                width: 14px;
                height: 2px;
                border-radius: 2px;
            }
        }
    }
    .wp-do-wrap{
        background: #f3f8ff;
        padding: 40px 0px 0 0px;
        .dw-title{
            font-size: 20px;
            color: #101010;
            text-align: center;
            font-weight: bold;
        }
        .dw-content{
            flex-wrap: wrap;
            padding: 40px 20px 0 20px;
            .dc-item{
                width: calc((100% - 30px)/2);
                height: 230px;
                .icon{
                    width: 36px;
                    height: 36px;
                }
                .title{
                    font-size: 13px;
                    color: #101010;
                    font-weight: bold;
                    padding-top: 20px;
                    padding-bottom: 15px;
                }
                .content{
                    font-size: 14px;
                    color: #808080;
                    line-height: 22px;
                    text-align: justify;
                }
            }
        }
        .dw-box{
            .db-item{
                .title{
                    font-size: 14px;
                    color: #000;
                    font-weight: bold;
                    padding: 30px 20px 0 20px;
                }
                .content{
                    font-size: 12px;
                    color: #808080;
                    line-height: 22px;
                    text-align: justify;
                    padding: 15px 20px;
                }
                .icon{
                    vertical-align: bottom;
                    width: 100%;
                }
            }
        }
    }
    .hw-f-tip{
        text-align: center;
        font-size: 12px;
        color: #999;
        background: #000;
        padding: 15px 0;
    }
    .hw-foot{
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 9999;
        .hw-f-news{
            padding: 20px;
            .logo{
                width: 40px;
                height: 40px;
            }
        }
        .hw-f-btn{
            padding: 15px 20px;
            background: #fff;
            box-shadow: 0px 0px 10px 4px rgba(100, 100, 100, 0.1);
            .btn{
                width: calc((100% - 30px)/2);
                background: #F2F2F2;
                border-radius: 4px;
                height: 45px;
                font-size: 14px;
                color: #101010;
            }
            .open{
                background: #0068ff;
                color: #fff;
            }
        }
    }
    .el-drawer__wrapper{
        top: 50px !important;
    }
}
.v-modal{
    top: 50px !important;
}
.drawer-wrap{
    .dw-item{
        padding: 20px;
        .title{
            font-size: 16px;
            color: #101010;
        }
        .icon{
            font-size: 16px;
            color: #101010;
        }
    }
}
.drawer-news-wrap{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    .news-wrap{
        padding: 30px 20px 20px 20px;
        .nw-title{
            padding: 20px 0;
            .logo{
                width: 120px;
                height: 30px;
            }
            .icon{
                .el-icon-minus{
                    font-size: 28px;
                    color: #101010;
                    font-weight: bold;
                }
            }
        }
        .nw-tip{
            font-size: 12px;
            color: #5a5a5a;
            padding-bottom: 20px;
        }
        .nw-box{
            height: calc(100vh - 390px);
            overflow-y: auto;
        }
        .nw-question{
            font-size: 14px;
            color: #000;
            background: #f7f7f7;
            border-radius: 6px;
            padding: 20px;
            margin-bottom: 20px;
            .nq-title{
                font-size: 16px;
                color: #101010;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .nq-item{
                font-size: 14px;
                text-align: justify;
                padding: 6px 0;
                color: #7b7b7b;
                line-height: 28px;
            }
        }
        .nw-input{
            border-radius: 6px;
            border: 1px solid #DCDFE6;
            .btn{
                height: 100%;
                .img{
                    width: 25px;
                    height: 25px;
                    vertical-align: bottom;
                }
            }
            .el-input__inner{
                height: 50px;
                border: none;
            }
            .el-input-group__append{
                border: none;
                background: rgba(0, 0, 0, 0);
            }
        }
    }
}
.el-popper{
    margin-right: 20px;
    border-radius: 10px;
}
.p-phone-wrap{
    .ppw-img{
        width: 50px;
        height: 50px;
    }
    .ppw-info{
        text-decoration: none;
        width: calc(100% - 70px);
        .name{
            font-size: 16px;
            color: #4e5969;
        }
        .phone{
            padding-top: 5px;
            font-size: 14px;
            color: #4e5969;
        }
    }
}
.el-dialog{
    margin: 0;
    margin-top: 0vh !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 10px;
    border-radius: 10px;
    .el-dialog__header{
        padding: 0 !important;
    }
}
.download-wrap{
    padding: 30px 0px;
    .img{
        width: 200px;
        height: 200px;
    }
    .tip{
        padding-top: 30px;
        font-size: 14px;
    }
}
</style>
